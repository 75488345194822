import { useEffect, useState } from 'react';
import { Tag } from 'antd';
import DownloadBtn from '../components/DownloadBtn';
import { API, graphqlOperation } from 'aws-amplify';
import { getUpload } from '../graphql/queries';

export default function DownloadBtnStatus({ upload, type, onStatusChange }) {
  const [status, setStatus] = useState(upload);

  useEffect(() => {
    setStatus(upload);
  }, [upload]);

  useEffect(() => {
    const getFile = async () => {
      try {
        const { data } = await API.graphql(graphqlOperation(getUpload, {
          id: upload.id,
        }));
        console.log('getfile ---> ', data.getUpload);
        const s = {
          id: data.getUpload.id,
          key: data.getUpload.file,
          status: data.getUpload.type,
        };
        setStatus(s);
        onStatusChange(type, s);
      } catch (err) {
        console.log('getfile', err);
      }
    };
    if (upload.id && upload.status !== 'validating...' && !upload.key) {
      getFile();
    }
  }, [upload, type, onStatusChange]);

  const tagStatus = (val) => {
    if (val.startsWith('validating...')) {
      return 'processing';
    } else if (val === 'error') {
      return 'error';
    } else {
      return 'success';
    }
  };

  if (status.key === 'null') {
    return null;
  }

  return (
    <>
      {status.status && (
        <Tag color={tagStatus(status.status)}>{status.status}</Tag>
      )}
      {status.status !== 'validating...' ? (
        <DownloadBtn file={status.key} bucket="smb-data-bucket" />
      ) : null}
    </>
  );
}