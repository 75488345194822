import {
  Form,
  Select,
  Button,
  Input,
  InputNumber,
} from 'antd';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

export default function StepOne({ next, state, prev, loading, stepOne }) {
  const onFinish = async (vals) => {
    next('stepTwo', vals);
  };

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      style={{ marginTop: 20 }}
      initialValues={state}
    >
      <Form.Item
        name="category"
        label="Category"
        tooltip="Advertiser category"
        rules={[{ required: true, message: 'Please enter a Category!' }]}
      >
        <Input placeholder="Category" />
      </Form.Item>
      <Form.Item
        name="audience"
        label="Audience"
        tooltip="Consumer audience the campaign targeted"
        rules={[{ required: true, message: 'Please enter Audience!' }]}
      >
        <Input placeholder="Audience" />
      </Form.Item>
      <Form.Item tooltip="Pre-exposure visits you want excluded from analysis" name="baselinePeriod" label="Baseline Period (days)">
        <Select placeholder="Baseline Period (days)">
          <Option value="none">None</Option>
          <Option value="7">7</Option>
          <Option value="14">14</Option>
          <Option value="30">30</Option>
          <Option value="60">60</Option>
        </Select>
      </Form.Item>

      <Form.Item tooltip="Number of days post-exposure you want to attribute visit to ad" name="lookBackPer" label="Look Back Period (days)">
        <Select placeholder="Look Back Period (days)">
          <Option value="none">None</Option>
          <Option value="7">7</Option>
          <Option value="14">14</Option>
          <Option value="30">30</Option>
          <Option value="60">60</Option>
        </Select>
      </Form.Item>

      {stepOne.screenType !== 'static' ? (
        <Form.Item
          name="adImpressions"
          label="Number of Ad Impressions"
          tooltip="Campaign ad impressions delivered"
          rules={[{ required: true, message: 'This field is required!' }]}
        >
          <InputNumber
            placeholder="Number of Ad Impressions"
            style={{ width: '100%' }}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
      ) : null}

      <Form.Item
        name="campaignCost"
        label="Total Campaign Cost"
        tooltip="Total Campaign Cost"
        rules={[{ required: true, message: 'This field is required!' }]}
      >
        <InputNumber
          placeholder="Total Campaign Cost"
          style={{ width: '100%' }}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Next
      </Button>
      <Button style={{ margin: '0 8px' }} disabled={loading} onClick={prev}>
        Previous
      </Button>
    </Form>
  );
}