import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Status from './Status';
import Upload from './Upload/indexx';
import Customer from './Customer';
import NewCustomer from './Customer/NewCustomer';
import Profile from './Profile';
import Campaign from './Status/Campaign';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Customer} />
      <Route path="/upload" component={Upload} />
      <Route path="/status" component={Status} />
      <Route path="/newcustomer" component={NewCustomer} />
      <Route path="/profile" component={Profile} />
      <Route path="/campaign/:id" component={Campaign} />
    </Switch>
  );
}