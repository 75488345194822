/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer($owner: String) {
    onCreateCustomer(owner: $owner) {
      id
      name
      emails
      status
      createdAt
      updatedAt
      owner
      campaigns {
        items {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer($owner: String) {
    onUpdateCustomer(owner: $owner) {
      id
      name
      emails
      status
      createdAt
      updatedAt
      owner
      campaigns {
        items {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer($owner: String) {
    onDeleteCustomer(owner: $owner) {
      id
      name
      emails
      status
      createdAt
      updatedAt
      owner
      campaigns {
        items {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateCampaign = /* GraphQL */ `
  subscription OnCreateCampaign($owner: String) {
    onCreateCampaign(owner: $owner) {
      id
      name
      accretCampId
      date
      advertDate
      visitDate
      baselinePeriod
      thirdPartyExp
      screenType
      adImpressions
      campaignCost
      fileFormat
      customerId
      category
      audience
      lookBackPer
      campaignUploadBusLocId
      campaignUploadScreenLocId
      campaignUploadExpDevId
      campaignUploadDigLogId
      createdAt
      updatedAt
      customer {
        id
        name
        emails
        status
        createdAt
        updatedAt
        owner
        campaigns {
          nextToken
        }
      }
      owner
      busLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      screenLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      ExpDev {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      DigLog {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const onUpdateCampaign = /* GraphQL */ `
  subscription OnUpdateCampaign($owner: String) {
    onUpdateCampaign(owner: $owner) {
      id
      name
      accretCampId
      date
      advertDate
      visitDate
      baselinePeriod
      thirdPartyExp
      screenType
      adImpressions
      campaignCost
      fileFormat
      customerId
      category
      audience
      lookBackPer
      campaignUploadBusLocId
      campaignUploadScreenLocId
      campaignUploadExpDevId
      campaignUploadDigLogId
      createdAt
      updatedAt
      customer {
        id
        name
        emails
        status
        createdAt
        updatedAt
        owner
        campaigns {
          nextToken
        }
      }
      owner
      busLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      screenLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      ExpDev {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      DigLog {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const onDeleteCampaign = /* GraphQL */ `
  subscription OnDeleteCampaign($owner: String) {
    onDeleteCampaign(owner: $owner) {
      id
      name
      accretCampId
      date
      advertDate
      visitDate
      baselinePeriod
      thirdPartyExp
      screenType
      adImpressions
      campaignCost
      fileFormat
      customerId
      category
      audience
      lookBackPer
      campaignUploadBusLocId
      campaignUploadScreenLocId
      campaignUploadExpDevId
      campaignUploadDigLogId
      createdAt
      updatedAt
      customer {
        id
        name
        emails
        status
        createdAt
        updatedAt
        owner
        campaigns {
          nextToken
        }
      }
      owner
      busLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      screenLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      ExpDev {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      DigLog {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const onCreateUpload = /* GraphQL */ `
  subscription OnCreateUpload {
    onCreateUpload {
      id
      file
      campaignId
      status
      type
      createdAt
      updatedAt
      campaign {
        id
        name
        accretCampId
        date
        advertDate
        visitDate
        baselinePeriod
        thirdPartyExp
        screenType
        adImpressions
        campaignCost
        fileFormat
        customerId
        category
        audience
        lookBackPer
        campaignUploadBusLocId
        campaignUploadScreenLocId
        campaignUploadExpDevId
        campaignUploadDigLogId
        createdAt
        updatedAt
        customer {
          id
          name
          emails
          status
          createdAt
          updatedAt
          owner
        }
        owner
        busLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        screenLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        ExpDev {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        DigLog {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateUpload = /* GraphQL */ `
  subscription OnUpdateUpload {
    onUpdateUpload {
      id
      file
      campaignId
      status
      type
      createdAt
      updatedAt
      campaign {
        id
        name
        accretCampId
        date
        advertDate
        visitDate
        baselinePeriod
        thirdPartyExp
        screenType
        adImpressions
        campaignCost
        fileFormat
        customerId
        category
        audience
        lookBackPer
        campaignUploadBusLocId
        campaignUploadScreenLocId
        campaignUploadExpDevId
        campaignUploadDigLogId
        createdAt
        updatedAt
        customer {
          id
          name
          emails
          status
          createdAt
          updatedAt
          owner
        }
        owner
        busLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        screenLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        ExpDev {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        DigLog {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteUpload = /* GraphQL */ `
  subscription OnDeleteUpload {
    onDeleteUpload {
      id
      file
      campaignId
      status
      type
      createdAt
      updatedAt
      campaign {
        id
        name
        accretCampId
        date
        advertDate
        visitDate
        baselinePeriod
        thirdPartyExp
        screenType
        adImpressions
        campaignCost
        fileFormat
        customerId
        category
        audience
        lookBackPer
        campaignUploadBusLocId
        campaignUploadScreenLocId
        campaignUploadExpDevId
        campaignUploadDigLogId
        createdAt
        updatedAt
        customer {
          id
          name
          emails
          status
          createdAt
          updatedAt
          owner
        }
        owner
        busLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        screenLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        ExpDev {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        DigLog {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
