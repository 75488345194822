import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Table, Button, message } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { listCustomers } from '../graphql/queries';

const columns = [
  // {
  //   title: '#',
  //   dataIndex: 'key',
  //   key: '#',
  // },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Email(s)',
    dataIndex: 'emails',
    key: 'emails',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: status => status ? 'Active' : 'Inactive',
  },
];

export default function Status({ history }) {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const getCustomers = async () => {
      try {
        setLoading(true);
        const { data } = await API.graphql(graphqlOperation(listCustomers));
        if (data) {
          setCustomers([...data.listCustomers.items]);
          setLoading(false);
        }
      } catch (err) {
        message.error('Something went wrong');
        console.log('error', err);
        setLoading(false);
      }
    };
    getCustomers();
  }, [])
  return (
    <div>
      <h1 style={{textAlign: 'center'}}>Customers</h1>
      <Button
        icon={<UserAddOutlined />}
        onClick={() => history.push('/newcustomer')}
        type="primary"
        style={{float: 'right', marginBottom: 15}}
      >
        New Customer
      </Button>
      <Table
        columns={columns}
        dataSource={customers}
        loading={loading}
        onRow={(record) => ({
          onClick: () => history.push(`/newcustomer/${record.id}`)
        })}
      />
    </div>
  );
}