import { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Radio,
  message,
} from 'antd';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createUpload } from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import UploadBtn from '../components/UploadBtn';
import DownloadBtn from '../components/DownloadBtn';
import DownloadBtnStatus from './DownloadBtnStatus';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

export default function StepThree({ finish, state, prev, campaignId, loading, stepThreeState }) {
  const initFiles = {
    status: '',
    id: null,
    key: null,
  };

  console.log('stepThree state', stepThreeState);
  const [busLoc, setBusLoc] = useState(stepThreeState.busLoc || initFiles);
  const [screenLoc, setScreenLoc] = useState(stepThreeState.screenLoc || initFiles);
  const [expDev, setExpDev] = useState(stepThreeState.expDev || initFiles);
  const [digLog, setDigLog] = useState(stepThreeState.digLog || initFiles);
  const [disabled, setDisabled] = useState(true);

  const onFinish = () => {
    finish({
      busLoc,
      screenLoc,
      expDev,
      digLog,
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onUpdateUpload)
    ).subscribe({
      next: ({ value }) => {
        console.log({ value });
        const { id, file } = value.data.onUpdateUpload;
        const status = prev => ({
          ...prev,
          status: value.data.onUpdateUpload.type,
          key: file,
        });
        if (busLoc.id === id) {
          setBusLoc(status);
        } else if (screenLoc.id === id) {
          setScreenLoc(status);
        } else if (expDev.id === id) {
          setExpDev(status);
        } else if (digLog.id === id) {
          setDigLog(status);
        }
      },
      error: error => console.warn(error)
    });

    return () => {
      subscription.unsubscribe();
    }
  }, [busLoc.id, screenLoc.id, expDev.id, digLog.id]);

  useEffect(() => {
    const sts = s => s === 'validated';
    if (sts(busLoc.status) && sts(screenLoc.status) && sts(expDev.status) && sts(digLog.status)) {
      setDisabled(false);
    } else if (sts(busLoc.status) && sts(screenLoc.status) && sts(digLog.status) && state.thirdPartyExp !== 'Yes') {
      setDisabled(false);
    } else if (sts(busLoc.status) && sts(screenLoc.status) && state.thirdPartyExp !== 'Yes' && state.screenType === 'static') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [busLoc.status, screenLoc.status, expDev.status, digLog.status, state.thirdPartyExp, state.screenType]);

  const handleCustomRequest = async (name, {
    file,
    onError,
    onProgress,
    onSuccess,
  }) => {
    console.log('HERES THE FILE', file);
    try {
      const uploadFile = await API.graphql(graphqlOperation(createUpload,
        {
          input: { file: 'null', status: 'VALIDATING', campaignId }
        }
      ));
      console.log('UPLOAD FILE ---> ', uploadFile);
      const status = prev => ({
        ...prev, // if file size is > than 10mb
        status: file.size > 10000000 ? 'uploading... it may take longer when uploading large files' : 'uploading...',
      });
      if (name === 'busLoc') {
        setBusLoc(status);
      } else if (name === 'screenLoc') {
        setScreenLoc(status);
      } else if (name === 'expDev') {
        setExpDev(status);
      } else if (name === 'digLog') {
        setDigLog(status);
      }
      if (uploadFile.data) {
        console.log('uplaod file state ---> ', state);
        const fileId = uploadFile.data.createUpload.id;
        Storage.put(`${fileId}__${campaignId}__${file.name}`, file, {
          progressCallback(progress) {
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100),
              },
              file,
            );
          },
          metadata: {
            key: JSON.stringify({ ...state, fileType: name }),
          },
        }).then(async (res) => {
          console.log('success response', res);
          onSuccess(res, file);
          const setId = prev => ({
            ...prev,
            id: uploadFile.data.createUpload.id,
            status: 'validating...',
            key: res.key,
          });
          console.log('file name----> ', name);
          if (name === 'busLoc') {
            setBusLoc(setId);
          } else if (name === 'screenLoc') {
            setScreenLoc(setId);
          } else if (name === 'expDev') {
            setExpDev(setId);
          } else if (name === 'digLog') {
            setDigLog(setId);
          }
          // setImageKey(res.key);
          message.success('Upload Success!');
        }).catch((err) => {
          onError();
          console.log('upload err', err);
          message.error('upload failed');
        });
      }
    } catch (uploadErr) {
      console.log('upload err', uploadErr);
      message.error('something went wrong');
    }
  }

  const handlePrev = () => {
    prev('stepThree', {
      busLoc,
      screenLoc,
      expDev,
      digLog,
    });
  };

  const handleChangeStatus = (type, status) => {
    if (type === 'busLoc') {
      setBusLoc(status);
    } else if (type === 'screenLoc') {
      setScreenLoc(status);
    } else if (type === 'expDev') {
      setExpDev(status);
    } else if (type === 'digLog') {
      setDigLog(status);
    }
  };

  const uploadTemplate = (type) => {
    return `upload-templates/${type}/${type}-upload-template.csv`
  }

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      style={{ marginTop: 20 }}
      initialValues={state}
    >
      <Form.Item name="fileFormat" label="File Format">
        <Radio.Group defaultValue="csv">
          <Radio value="csv">Comma-Separated (CSV)</Radio>
          <Radio disabled value="xls">Excel (XLS/XLSX)</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Business Locations" tooltip="Locations to measure foot traffic lift">
        <Form.Item
          name="busLoc"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          noStyle
        >
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <UploadBtn
              onCustomRequest={(props) => handleCustomRequest('busLoc', props)}
              name={'busLoc'}
            />
            <DownloadBtn
              file={uploadTemplate('business-locations')}
              text="Download sample file"
              bucket="smb-data-bucket"
            />
          </div>
          <DownloadBtnStatus
            upload={busLoc}
            type="busLoc"
            onStatusChange={handleChangeStatus}
          />
        </Form.Item>
      </Form.Item>

      <Form.Item label="Screen Locations" tooltip="Locations of screens in which the advertising ran">
        <Form.Item
          name="screenLoc"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          noStyle
        >
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <UploadBtn
              onCustomRequest={(props) => handleCustomRequest('screenLoc', props)}
              name={'screenLoc'}
            />
            <DownloadBtn
              file={uploadTemplate('screen-locations')}
              text="Download sample file"
              bucket="smb-data-bucket"
            />
          </div>
          <DownloadBtnStatus
            upload={screenLoc}
            type="screenLoc"
            onStatusChange={handleChangeStatus}
          />
        </Form.Item>
      </Form.Item>

      {state.thirdPartyExp === 'Yes' ? (
        <Form.Item label="Exposed Devices" tooltip="Mobile device IDs of consumers who saw the advertising">
          <Form.Item
            name="expDev"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
              <UploadBtn
                onCustomRequest={(props) => handleCustomRequest('expDev', props)}
                name={'expDev'}
              />
              <DownloadBtn
                file={uploadTemplate('third-party-exposed-devices')}
                text="Download sample file"
                bucket="smb-data-bucket"
              />
            </div>
            <DownloadBtnStatus
              upload={expDev}
              type="expDev"
              onStatusChange={handleChangeStatus}
            />
          </Form.Item>
        </Form.Item>
      ) : null}
      {state.thirdPartyExp === 'No' && (state.screenType === 'digital' || state.screenType === 'staticdigital') ?
        <Form.Item label="Digital Logs" tooltip="A digital log file that defines when ads ran on a particular digital screen and for how long">
          <Form.Item
            name="digLog"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
              <UploadBtn
                onCustomRequest={(props) => handleCustomRequest('digLog', props)}
                name={'digLog'}
              />
              <DownloadBtn
                file={uploadTemplate('digital-logs')}
                text="Download sample file"
                bucket="smb-data-bucket"
              />
            </div>
            <DownloadBtnStatus
              upload={digLog}
              type="digLog"
              onStatusChange={handleChangeStatus}
            />
          </Form.Item>
        </Form.Item>
        : null}
      <Button
        loading={loading}
        disabled={disabled || loading}
        type="primary"
        htmlType="submit"
      >
        Submit
      </Button>
      <Button style={{ margin: '0 8px' }} onClick={handlePrev}>
        Previous
      </Button>
    </Form>
  );
}