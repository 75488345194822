import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Layout, Menu, Dropdown, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  UploadOutlined,
  BulbOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Routes from './routes';
import logo from './images/logo.png';
import './App.css';

const { Header, Content, Footer, Sider } = Layout;

function App() {
  const history = useHistory();

  const logout = () => {
    Auth.signOut();
    window.location.reload();
  };
  const { location } = history;
  const path = location.pathname;
  let keys = '1';
  if (path === '/') {
    keys = '1';
  } else if (path === '/upload') {
    keys = '2';
  } else if (path === '/status') {
    keys = '9';
  } else if (path === '/profile') {
    keys = '10';
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="header">
        <img
          className="accre_logo"
          alt="logo"
          src={logo}
        />
        <Dropdown
          overlay={
            <Menu theme="dark">
              <Menu.Item
                key="10"
                onClick={() => history.push('/profile')}
                icon={<UserOutlined />}
              >
                Profile
              </Menu.Item>
              <Menu.Item onClick={logout} icon={<LogoutOutlined />}>
                Logout
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="default" className="user-btn" shape="circle" icon={<UserOutlined />} size="large" />
        </Dropdown>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <Menu.Item
              key="1"
              onClick={() => history.push('/')}
              icon={<UsergroupAddOutlined />}
            >
              Customers
            </Menu.Item>
            <Menu.Item
              key="2"
              active
              onClick={() => history.push('/upload')}
              icon={<UploadOutlined />}
            >
              New Upload
            </Menu.Item>

            <Menu.Item
              key="9"
              onClick={() => history.push('/status')}
              icon={<BulbOutlined />}
            >
              Status
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px', background: '#fff' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: '#fff',
            }}
          >
            <Routes />
          </Content>
          <Footer style={{ textAlign: 'center', background: '#fff' }}>Accretive Media © 2021</Footer>
        </Layout>
      </Layout>
    </Layout>
  );

}

const signUpConfig = {
  hiddenDefaults: ['username'],
  signUpFields: [
    {
      label: 'Email',
      key: 'username', // !!!
      required: true,
      displayOrder: 1,
      type: 'email',
      custom: false
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
      custom: false
    },
    {
      label: 'Phone Number',
      key: 'phone_number',
      required: true,
      displayOrder: 3,
      type: 'tel',
      custom: false
    }
  ]
};


export default withAuthenticator(App, true, [], null, null, signUpConfig);
