import { useRef, useState, useEffect } from 'react';
import {
  Form,
  Button,
  Input,
  message,
  Checkbox,
} from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { createCustomer, updateCustomer } from '../graphql/mutations';
import { getCustomer } from '../graphql/queries';
import { ArrowLeftOutlined } from '@ant-design/icons';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

export default function NewCustomer({ location, history }) {
  const formRef = useRef();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadForm, setLoadForm] = useState(false);
  const [active, setActive] = useState(true);
  const id = location.pathname.split('/')[2];
  const onFinish = async ({ name, emails }) => {
    setLoading(true);
    const input = {
      name,
      emails,
      status: active,
    };

    try {
      let customer = {};
      if (id) {
        input.id = id;
        customer = await API.graphql(graphqlOperation(updateCustomer, { input }));
      } else {
        customer = await API.graphql(graphqlOperation(createCustomer, { input }));
      }
      if (customer.data) {
        const status = id ? 'Updated' : 'Added';
        message.success(`Customer Successfully ${status}!`);
      }
      setLoading(false);
    } catch (err) {
      message.error('Something went wrong');
      setLoading(false);
    }
    if (!id) {
      formRef.current.resetFields();
    }
  };

  
  useEffect(() => {
    const getCustomerById = async () => {
      try {
        setLoadForm(true);
        const { data } = await API.graphql(graphqlOperation(getCustomer, { id }));
        console.log('get customer', data);
        setActive(data.getCustomer.status);
        form.setFieldsValue({
          name: data.getCustomer.name,
          emails: data.getCustomer.emails,
        });
        setLoadForm(false);
      } catch (err) {
        setLoadForm(false);
        console.log('get customer err', err);
      }
    }
    if (id) {
      getCustomerById();
    }
  }, [id, form]);

  return (
    <Form
      name="validate_other"
      ref={formRef}
      form={form}
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        'input-number': 3,
        'checkbox-group': ['A', 'B'],
        rate: 3.5,
      }}
    >
      <Button
        type="primary"
        shape="circle"
        icon={<ArrowLeftOutlined />}
        onClick={() => history.goBack()}
      />
      <h1 style={{ textAlign: 'center' }}>{id ? 'Update' : 'New'} Customer</h1>
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter Customer Name!' }]}
      >
        <Input placeholder="Customer Name" disabled={loadForm} />
      </Form.Item>

      <Form.Item
        name="emails"
        label="Email(s)"
        rules={[{ required: true, message: 'Please enter Customer Emails!' }]}
      >
        <Input.TextArea rows={4} placeholder="Emails" disabled={loadForm} />
      </Form.Item>

      {id ? (
        <Form.Item
          label="ID"
        >
          <Input placeholder="ID" disabled value={id} />
        </Form.Item>
      ) : null}

      {id ? (
        <Form.Item
          label="Active"
        >
          <Checkbox onChange={e => setActive(e.target.checked)} checked={active} />
        </Form.Item>
      ) : null}

      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 6,
        }}
      >
        <Button type="primary" htmlType="submit" loading={loading} disabled={loading || loadForm}>
          {id ? 'Update' : 'Save'}
        </Button>
      </Form.Item>
    </Form>
  );
};
