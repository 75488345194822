/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      emails
      status
      createdAt
      updatedAt
      owner
      campaigns {
        items {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      emails
      status
      createdAt
      updatedAt
      owner
      campaigns {
        items {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      emails
      status
      createdAt
      updatedAt
      owner
      campaigns {
        items {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      name
      accretCampId
      date
      advertDate
      visitDate
      baselinePeriod
      thirdPartyExp
      screenType
      adImpressions
      campaignCost
      fileFormat
      customerId
      category
      audience
      lookBackPer
      campaignUploadBusLocId
      campaignUploadScreenLocId
      campaignUploadExpDevId
      campaignUploadDigLogId
      createdAt
      updatedAt
      customer {
        id
        name
        emails
        status
        createdAt
        updatedAt
        owner
        campaigns {
          nextToken
        }
      }
      owner
      busLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      screenLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      ExpDev {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      DigLog {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      name
      accretCampId
      date
      advertDate
      visitDate
      baselinePeriod
      thirdPartyExp
      screenType
      adImpressions
      campaignCost
      fileFormat
      customerId
      category
      audience
      lookBackPer
      campaignUploadBusLocId
      campaignUploadScreenLocId
      campaignUploadExpDevId
      campaignUploadDigLogId
      createdAt
      updatedAt
      customer {
        id
        name
        emails
        status
        createdAt
        updatedAt
        owner
        campaigns {
          nextToken
        }
      }
      owner
      busLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      screenLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      ExpDev {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      DigLog {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      name
      accretCampId
      date
      advertDate
      visitDate
      baselinePeriod
      thirdPartyExp
      screenType
      adImpressions
      campaignCost
      fileFormat
      customerId
      category
      audience
      lookBackPer
      campaignUploadBusLocId
      campaignUploadScreenLocId
      campaignUploadExpDevId
      campaignUploadDigLogId
      createdAt
      updatedAt
      customer {
        id
        name
        emails
        status
        createdAt
        updatedAt
        owner
        campaigns {
          nextToken
        }
      }
      owner
      busLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      screenLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      ExpDev {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      DigLog {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const createUpload = /* GraphQL */ `
  mutation CreateUpload(
    $input: CreateUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    createUpload(input: $input, condition: $condition) {
      id
      file
      campaignId
      status
      type
      createdAt
      updatedAt
      campaign {
        id
        name
        accretCampId
        date
        advertDate
        visitDate
        baselinePeriod
        thirdPartyExp
        screenType
        adImpressions
        campaignCost
        fileFormat
        customerId
        category
        audience
        lookBackPer
        campaignUploadBusLocId
        campaignUploadScreenLocId
        campaignUploadExpDevId
        campaignUploadDigLogId
        createdAt
        updatedAt
        customer {
          id
          name
          emails
          status
          createdAt
          updatedAt
          owner
        }
        owner
        busLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        screenLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        ExpDev {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        DigLog {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateUpload = /* GraphQL */ `
  mutation UpdateUpload(
    $input: UpdateUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    updateUpload(input: $input, condition: $condition) {
      id
      file
      campaignId
      status
      type
      createdAt
      updatedAt
      campaign {
        id
        name
        accretCampId
        date
        advertDate
        visitDate
        baselinePeriod
        thirdPartyExp
        screenType
        adImpressions
        campaignCost
        fileFormat
        customerId
        category
        audience
        lookBackPer
        campaignUploadBusLocId
        campaignUploadScreenLocId
        campaignUploadExpDevId
        campaignUploadDigLogId
        createdAt
        updatedAt
        customer {
          id
          name
          emails
          status
          createdAt
          updatedAt
          owner
        }
        owner
        busLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        screenLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        ExpDev {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        DigLog {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteUpload = /* GraphQL */ `
  mutation DeleteUpload(
    $input: DeleteUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    deleteUpload(input: $input, condition: $condition) {
      id
      file
      campaignId
      status
      type
      createdAt
      updatedAt
      campaign {
        id
        name
        accretCampId
        date
        advertDate
        visitDate
        baselinePeriod
        thirdPartyExp
        screenType
        adImpressions
        campaignCost
        fileFormat
        customerId
        category
        audience
        lookBackPer
        campaignUploadBusLocId
        campaignUploadScreenLocId
        campaignUploadExpDevId
        campaignUploadDigLogId
        createdAt
        updatedAt
        customer {
          id
          name
          emails
          status
          createdAt
          updatedAt
          owner
        }
        owner
        busLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        screenLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        ExpDev {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        DigLog {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
