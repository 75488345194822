import { useEffect, useState } from 'react';
import { Spin, Tag } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { API, graphqlOperation } from 'aws-amplify';
import DownloadBtn from '../components/DownloadBtn';
import { getUpload } from '../graphql/queries';

export default function CampaignFile({ id }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        setLoading(true);
        const { data } = await API.graphql(graphqlOperation(getUpload, { id }));
        console.log('get upload ----', data);
        setFile(data.getUpload);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log('errorooror', err);
      }
    };
    fetchCampaign();
  }, [id]);

  if (loading) {
    return <Spin />;
  }

  const err = file.type === 'error';
  if (!file.file || file.file === '' || file.file === 'null') {
    return null;
  }
  return (
    <>
      <DownloadBtn file={file.file} bucket="smb-data-bucket" />
      <Tag icon={err ? <CloseCircleOutlined /> : <CheckCircleOutlined />} color={err ? 'error' : 'success'}>
        {err ? 'Error with validation' : 'Validated'}
      </Tag>
    </>
  );
}