import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Form,
  Button,
  Input
} from 'antd';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

export default function Profile() {
  const [form] = Form.useForm();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const updateProfile = await Auth.updateUserAttributes(user, { ...values});
      console.log('update profile', updateProfile);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('udpate profile ', err);
    }
  };

  useEffect(() => {
    const user = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      console.log('adsdadsa', attributes);
      setUser(user);
      form.setFieldsValue({
        name: attributes.name,
        email: attributes.email,
        phone_number: attributes.phone_number,
      });
    }
    user();
  }, [form])

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        'input-number': 3,
        'checkbox-group': ['A', 'B'],
        rate: 3.5,
      }}
      form={form}
    >
      <h1 style={{textAlign: 'center'}}>Profile</h1>
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter your Name!' }]}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: 'Please enter your Email!' }]}
      >
        <Input placeholder="Email" type="email" disabled />
      </Form.Item>

      <Form.Item
        name="phone_number"
        label="Phone Number"
        rules={[{ required: true, message: 'Please enter your Phone Number!' }]}
      >
        <Input placeholder="Phone Number" disabled />
      </Form.Item>

      {/* <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: 'Please enter new Password!' }]}
      >
        <Input placeholder="Password" type="password" />
      </Form.Item>

      <Form.Item
        name="confPassword"
        label="Confirm Password"
        rules={[{ required: true, message: 'Please confirm new Password!' }]}
      >
        <Input placeholder="Confirm Password" type="password" />
      </Form.Item> */}

      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 6,
        }}
      >
        <Button type="primary" loading={loading} htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
