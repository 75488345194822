import React from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

export default function UploadBtn({ onCustomRequest, name, disabled }) {
  return (
    <Upload
      customRequest={onCustomRequest}
      maxCount={1}
      name={name}
      disabled={disabled}
      progress={percent => `${parseFloat(percent.toFixed(2))}%`}
      accept=".csv,.zip,.gz"
    >
      <Button disabled={disabled} icon={<UploadOutlined />}>Click to upload</Button>
    </Upload>
  );
}