import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
  Form,
  Select,
  Button,
  DatePicker,
  Input,
} from 'antd';
import { listCustomers } from '../graphql/queries';

const { Option } = Select;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const rangeConfig = {
  rules: [
    {
      type: 'array',
      required: true,
      message: 'Please select time!',
    },
  ],
};

export default function StepOne({ next, state }) {
  const [loadCustomers, setLoadCustomers] = useState(false);
  const [customers, setCustomers] = useState([]);

  const getCustomers = async () => {
    try {
      setLoadCustomers(true);
      const { data } = await API.graphql(graphqlOperation(listCustomers, { filter: { status: { eq: true } } }));
      console.log(data);
      if (data) {
        setCustomers([...data.listCustomers.items]);
        setLoadCustomers(false);
      }
    } catch (err) {
      console.log('error', err);
      setLoadCustomers(false);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const onFinish = async (vals) => {
    next('stepOne', vals);
  };

  const disableFutureDates = (current) => {
    return current && current.valueOf() > Date.now();
  }

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      style={{ marginTop: 20 }}
      initialValues={state}
    >
      <Form.Item
        name="customerId"
        label="(Customer) Select"
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Select a customer!',
          },
        ]}
      >
        <Select placeholder="Select a customer" loading={loadCustomers}>
          {customers.map((customer => (
            <Option key={customer.id} value={customer.id}>{customer.name}</Option>
          )))}
        </Select>
      </Form.Item>

      <Form.Item
        name="name"
        label="Campaign Name"
        rules={[{ required: true, message: 'Please enter your Campaign Name!' }]}
      >
        <Input placeholder="Campaign Name" />
      </Form.Item>

      <Form.Item
        name="accretCampId"
        label="Accretive Campaign ID"
        rules={[{ required: true, message: 'Please enter your Accretive Campaign ID!' }]}
      >
        <Input placeholder="External Campaign Id" />
      </Form.Item>

      <Form.Item name="advertDate" label="Advertising Date" {...rangeConfig}>
        <RangePicker disabledDate={disableFutureDates} />
      </Form.Item>

      <Form.Item name="visitDate" label="Visitation Date" {...rangeConfig}>
        <RangePicker disabledDate={disableFutureDates} />
      </Form.Item>

      <Form.Item
        name="thirdPartyExp"
        label="(Exposed Devices) Select"
        hasFeedback
        tooltip="Do you have your own exposed devices (Yes) or would you like Accretive to generate (No)?"
        rules={[
          {
            required: true,
            message: 'Please select an option!',
          },
        ]}
      >
        <Select placeholder="3rd Party Exposed Devices">
          <Option value="Yes">Yes</Option>
          <Option value="No">No</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="screenType"
        label="(Screen Type) Select"
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please select an option!',
          },
        ]}
      >
        <Select placeholder="Screen Type">
          <Option value="static">Static</Option>
          <Option value="digital">Digital</Option>
          <Option value="staticdigital">Static and Digital</Option>
        </Select>
      </Form.Item>
      <Button htmlType="submit" type="primary">
        Next
      </Button>
      {/* <Button style={{ margin: '0 8px' }} onClick={next}>
        Next
      </Button> */}
    </Form>
  );
}