import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Steps, message, Spin } from 'antd';
import moment from 'moment';
import { getCampaign } from '../graphql/queries';
import { createCampaign, updateCampaign } from '../graphql/mutations';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const { Step } = Steps;

const steps = [
  {
    title: 'Create Campaign Wizard - Step 1',
    content: 'First-content',
  },
  {
    title: 'Create Campaign Wizard - Step 2',
    content: 'Second-content',
  },
  {
    title: 'Create Campaign Wizard - Step 3',
    content: 'Last-content',
  },
];

export default function Upload() {
  const [current, setCurrent] = useState(0);
  const [stepOne, setStepOne] = useState({});
  const [stepTwo, setStepTwo] = useState({});
  const [stepThree, setStepThree] = useState({});
  const [campaignId, setCampaignId] = useState(null);
  const [creatingCampaign, setCreatingCampaign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadCamp, setLoadCamp] = useState(false);
  const [id, setId] = useState(null);
  const history = useHistory();
  const search = history.location.search || null;

  useEffect(() => {
    if (search) {
      setId(search.split('?id=')[1]);
    } else {
      setId(null);
    }
  }, [search]);

  useEffect(() => {
    console.log('heres the id', id);

    const fetchCamp = async () => {
      try {
        setLoadCamp(true);
        const { data } = await API.graphql(graphqlOperation(getCampaign, {
          id,
        }));
        const camp = data.getCampaign;
        console.log('campapapp', camp);
        const dates = (date) => date.split('_');
        setCampaignId(camp.id);
        setStepOne({
          name: camp.name,
          customerId: camp.customerId,
          accretCampId: camp.accretCampId,
          advertDate: [moment(Number(dates(camp.advertDate)[0])), moment(Number(dates(camp.advertDate)[1]))],
          visitDate: [moment(Number(dates(camp.visitDate)[0])), moment(Number(dates(camp.visitDate)[1]))],
          thirdPartyExp: camp.thirdPartyExp,
          screenType: camp.screenType,
        });
        setStepTwo({
          category: camp.category,
          audience: camp.audience,
          baselinePeriod: camp.baselinePeriod,
          lookBackPer: camp.lookBackPer,
          adImpressions: camp.adImpressions,
          campaignCost: camp.campaignCost,
        });
        setStepThree({
          busLoc: {
            id: camp.campaignUploadBusLocId,
            status: '',
            key: null, 
          },
          screenLoc: {
            id: camp.campaignUploadScreenLocId,
            status: '',
            key: null, 
          },
          expDev: {
            id: camp.campaignUploadExpDevId,
            status: '',
            key: null, 
          },
          digLog: {
            id: camp.campaignUploadDigLogId,
            status: '',
            key: null, 
          },
        });
        setLoadCamp(false);
      } catch (err) {
        setLoadCamp(false);
        console.log('errro fetching campaign', err);
      }
    };
    if (id) {
      fetchCamp();
    }
  }, [id]);

  const campaignCreate = async (vals) => {
    try {
      setCreatingCampaign(true);
      const { data } = await API.graphql(graphqlOperation(createCampaign, 
        {
          input: {
            customerId: stepOne.customerId,
          }
        }
      ));
      setCreatingCampaign(false);
      setStepTwo(vals);
      console.log('campaign created', data.createCampaign);
      setCampaignId(data.createCampaign.id);
      setCurrent(current + 1);
    } catch (err) {
      console.log(err);
      setCreatingCampaign(false);
      message.error('Could not create Campaign');
    }
  };

  const next = (name, vals) => {
    if (name === 'stepOne') {
      setStepOne(vals);
      setCurrent(current + 1);
    } else if (name === 'stepTwo') {
      if (!campaignId && !id) {
        campaignCreate(vals);
      } else {
        setStepTwo(vals);
        setCurrent(current + 1);
      }
    }
  };

  const prev = (name, vals) => {
    if (name === 'stepThree') {
      setStepThree(vals);
    }
    setCurrent(current - 1);
  };

  const handleFinish = async (data) => {
    const { advertDate, visitDate } = stepOne;
    const getDateRange = (dateRange) => `${new Date(dateRange[0]._d).getTime()}_${new Date(dateRange[1]._d).getTime()}`;
    try {
      setLoading(true);
      console.log('HANDLE FINISH=====', data);
      const input = {
        id: campaignId,
        customerId: stepOne.customerId,
        name: stepOne.name,
        accretCampId: stepOne.accretCampId,
        advertDate: getDateRange(advertDate),
        visitDate: getDateRange(visitDate),
        thirdPartyExp: stepOne.thirdPartyExp,
        screenType: stepOne.screenType,
        category: stepTwo.category,
        audience: stepTwo.audience,
        baselinePeriod: stepTwo.baselinePeriod,
        lookBackPer: stepTwo.lookBackPer,
        adImpressions: stepTwo.adImpressions,
        campaignCost: stepTwo.campaignCost,
        fileFormat: 'csv',
        campaignUploadBusLocId: data.busLoc.id,
        campaignUploadScreenLocId: data.screenLoc.id,
        campaignUploadExpDevId: data.expDev.id,
        campaignUploadDigLogId: data.digLog.id,
      };

      const updatedCampaign = await API.graphql(graphqlOperation(updateCampaign, {input}));
      console.log('updated campaign ---', updatedCampaign);
      setLoading(false);
      history.push('/status');
    } catch (err) {
      message.error('Something went wrong');
      console.log('updated cam error ---', err);
      setLoading(false);
    }
  };

  if (loadCamp) {
    return <Spin />;
  }

  return (
    <>
      <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">
        {current === 0 ? (
          <StepOne next={next} state={stepOne} />
        ) : null}
        {current === 1 ? (
          <StepTwo
            next={next}
            prev={prev}
            state={stepTwo}
            stepOne={stepOne}
            loading={creatingCampaign}
          />
        ) : null}
        {current === 2 ? (
          <StepThree
            campaignId={campaignId}
            finish={handleFinish}
            state={{...stepOne, ...stepTwo}}
            stepThreeState={stepThree}
            loading={loading}
            prev={prev}
          />
        ) : null}
      </div>
    </>
  );
};
