/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      emails
      status
      createdAt
      updatedAt
      owner
      campaigns {
        items {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        emails
        status
        createdAt
        updatedAt
        owner
        campaigns {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      accretCampId
      date
      advertDate
      visitDate
      baselinePeriod
      thirdPartyExp
      screenType
      adImpressions
      campaignCost
      fileFormat
      customerId
      category
      audience
      lookBackPer
      campaignUploadBusLocId
      campaignUploadScreenLocId
      campaignUploadExpDevId
      campaignUploadDigLogId
      createdAt
      updatedAt
      customer {
        id
        name
        emails
        status
        createdAt
        updatedAt
        owner
        campaigns {
          nextToken
        }
      }
      owner
      busLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      screenLoc {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      ExpDev {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      DigLog {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        accretCampId
        date
        advertDate
        visitDate
        baselinePeriod
        thirdPartyExp
        screenType
        adImpressions
        campaignCost
        fileFormat
        customerId
        category
        audience
        lookBackPer
        campaignUploadBusLocId
        campaignUploadScreenLocId
        campaignUploadExpDevId
        campaignUploadDigLogId
        createdAt
        updatedAt
        customer {
          id
          name
          emails
          status
          createdAt
          updatedAt
          owner
        }
        owner
        busLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        screenLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        ExpDev {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        DigLog {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUpload = /* GraphQL */ `
  query GetUpload($id: ID!) {
    getUpload(id: $id) {
      id
      file
      campaignId
      status
      type
      createdAt
      updatedAt
      campaign {
        id
        name
        accretCampId
        date
        advertDate
        visitDate
        baselinePeriod
        thirdPartyExp
        screenType
        adImpressions
        campaignCost
        fileFormat
        customerId
        category
        audience
        lookBackPer
        campaignUploadBusLocId
        campaignUploadScreenLocId
        campaignUploadExpDevId
        campaignUploadDigLogId
        createdAt
        updatedAt
        customer {
          id
          name
          emails
          status
          createdAt
          updatedAt
          owner
        }
        owner
        busLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        screenLoc {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        ExpDev {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
        DigLog {
          id
          file
          campaignId
          status
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listUploads = /* GraphQL */ `
  query ListUploads(
    $filter: ModelUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        file
        campaignId
        status
        type
        createdAt
        updatedAt
        campaign {
          id
          name
          accretCampId
          date
          advertDate
          visitDate
          baselinePeriod
          thirdPartyExp
          screenType
          adImpressions
          campaignCost
          fileFormat
          customerId
          category
          audience
          lookBackPer
          campaignUploadBusLocId
          campaignUploadScreenLocId
          campaignUploadExpDevId
          campaignUploadDigLogId
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
