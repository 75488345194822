import { useEffect, useState } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Table, Button, message, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { listCampaigns } from '../graphql/queries';
import { deleteCampaign, deleteUpload } from '../graphql/mutations';

export default function Status({ history }) {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [delId, setDelId] = useState('');

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, rec) => (
        <a
          href="javascript:void(0)"
          onClick={() => history.push(`/campaign/${rec.id}`)}
        >{text}</a>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: cust => cust.name,
    },
    {
      title: 'Cost',
      key: 'campaignCost',
      dataIndex: 'campaignCost',
      render: val => `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') 
    },
    {
      title: 'Ad Impressions',
      key: 'adImpressions',
      dataIndex: 'adImpressions',
      render: val => `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: 'Created',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: d => new Date(d).toDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            type="primary"
            onClick={() => history.push(`/upload?id=${record.id}`)}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            disabled={record.id === delId}
            onClick={() => handleDelete(record)}
            loading={record.id === delId}
          />
        </Space>
      )
    }
  ];

  const getFileName = (file) => {
    if (!file) {
      return null;
    }
    console.log('file ---> ', file);
    const fileSplit = file.split('/');
    const fileLen = fileSplit.length;
    if (fileLen > 1) {
      return fileSplit[fileLen - 1];
    } else {
      return null;
    }
  };

  const removeFile = async (file, bucket) => {
    let fileConfig = {
      level: 'public',
    };
    // Delete from smb-data-bucket
    if (bucket) {
      fileConfig = {
        level: 'public',
        bucket,
        customPrefix: {
          public: '',
        },
      };
    }

    try {
      const delFile = await Storage.vault.remove(file, fileConfig);
      return delFile;
    } catch (err) {
      return err;
    }
  };

  const deleteRecord = async (id) => {
    if (!id) {
      return null;
    }

    try {
      const delUpload = await API.graphql(graphqlOperation(deleteUpload, {
        input: {id},
      }));
      const fileName = getFileName(delUpload.data.deleteUpload.file);
      console.log('FILE NAME', fileName);
      if (fileName) {
        const delFromSmbData = await removeFile(delUpload.data.deleteUpload.file, 'smb-data-bucket');
        console.log('dle from smb data', delFromSmbData);
        const delFromCsvUpl = await removeFile(fileName);
        console.log('dle from csv uploads', delFromCsvUpl);
      }

      console.log('DEL BUS LOC', delUpload);
      return delUpload;
    } catch (err) {
      return err;
    }
  };

  const handleDelete = async (record) => {
    console.log('HANDLE DELETE', record);
    try {
      const {id} = record;
      setDelId(id);
      const { data } = await API.graphql(graphqlOperation(deleteCampaign, {
        input: {id},
      }));
      const recordIds = ['campaignUploadBusLocId', 'campaignUploadExpDevId', 'campaignUploadScreenLocId'];
      for (let i = 0; i < recordIds.length; i++) {
        const campId = recordIds[i];
        try {
          await deleteRecord(record[campId]);
        } catch (err) {
          console.log(`${campId} ---> ${err}`);
        }
      }
      setDelId(null);
      campaigns.splice(campaigns.findIndex(cust => id === cust.id), 1);
      setCampaigns([...campaigns]);
      console.log('respopsopso', data.deleteCampaign);
    } catch (err) {
      setDelId(null);
      console.log('errorororo', err);
    }
  };

  useEffect(() => {
    const getCampaigns = async () => {
      try {
        setLoading(true);
        const { data } = await API.graphql(graphqlOperation(listCampaigns));
        if (data) {
          setCampaigns([...data.listCampaigns.items]);
          setLoading(false);
        }
      } catch (err) {
        message.error('Something went wrong');
        console.log('error', err);
        setLoading(false);
      }
    };
    getCampaigns();
  }, []);

  return (
    <div>
      <h1 style={{textAlign: 'center'}}>Campaigns</h1>
      {/* <Button
        icon={<UserAddOutlined />}
        onClick={() => history.push('/newcustomer')}
        type="primary"
        style={{float: 'right', marginBottom: 15}}
      >
        New Customer
      </Button> */}
      <Table
        columns={columns}
        dataSource={campaigns}
        loading={loading}
        // onRow={(record) => ({
        //   onClick: () => history.push(`/newcustomer/${record.id}`)
        // })}
      />
    </div>
  );
}