/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:04670dd7-5bae-4be3-93b2-3de79a4641a2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lXmCr93M8",
    "aws_user_pools_web_client_id": "78ondb8md929ldaecsksukrb6i",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wec6nnukrfbn3jdk3twvjwlygm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fakeApiId123456",
    "aws_user_files_s3_bucket": "csvuploads234817-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
