import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import config from '../aws-exports';

export default function DownloadBtn({ file, bucket = config.aws_user_files_s3_bucket, text = 'Download File', ...rest }) {
  const [loading, setLoading] = useState(false);
  const [href, setHref] = useState('');
  useEffect(() => {
    const getFile = async () => {
      try {
        setLoading(true);
        let fileConfig = {
          level: 'public',
        };
        console.log('bucket', bucket);
        if (bucket !== config.aws_user_files_s3_bucket) {
          fileConfig = {
            level: 'public',
            bucket,
            customPrefix: {
              public: '',
            },
          };
        }

        const filePath = await Storage.vault.get(file, fileConfig);
        console.log(filePath);
        setLoading(false);
        setHref(filePath);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    getFile();
  }, [file, bucket]);
  if (!file) {
    return null;
  }
  return (
    <Button
      type="link"
      {...rest}
      icon={<DownloadOutlined />}
      href={href}
      disabled={loading}
      loading={loading}
      download={href}
    >
      {text}
    </Button>
  );
}