import React, { useEffect, useState } from 'react';
import { Descriptions, Skeleton, Card, Button } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getCampaign } from '../graphql/queries';
import CampaignFile from './CampaignFile';

export default function Campaign({ location, history }) {
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState({});
  const path = location.pathname;
  const id = path.split('/')[2];

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        setLoading(true);
        const { data } = await API.graphql(graphqlOperation(getCampaign, { id }));
        console.log('get campaign ----', data);
        setCampaign(data.getCampaign);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log('errorooror', err);
      }
    };
    fetchCampaign();
  }, [id]);

  console.log(id);

  if (loading || !campaign.customer) {
    return (
      <Card>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Card>
    );
  }

  const getRange = (range) => {
    const start = range.split('_')[0]
    const end = range.split('_')[1]

    return `${new Date(Number(start)).toDateString()} to ${new Date(Number(end)).toDateString()}`
  }

  return (
    <>
      <Button
        type="primary"
        shape="circle"
        icon={<ArrowLeftOutlined />}
        onClick={() => history.goBack()}
        style={{ marginBottom: 15 }}
      />
      <Card>
        <Descriptions title="Campaign Info" layout="vertical" bordered>
          <Descriptions.Item label="Customer">{campaign.customer.name}</Descriptions.Item>
          <Descriptions.Item label="Campaign Name">{campaign.name}</Descriptions.Item>
          {campaign.accretCampId ? (
            <Descriptions.Item label="Accretive Campaign Id">{campaign.accretCampId}</Descriptions.Item>
          ) : null}
          <Descriptions.Item label="Baseline Period">{campaign.baselinePeriod}</Descriptions.Item>
          <Descriptions.Item label="Avertising Date">
            {getRange(campaign.advertDate || campaign.date)}
          </Descriptions.Item>
          {campaign.visitDate ? (
            <Descriptions.Item label="Visit Date">
              {getRange(campaign.visitDate)}
            </Descriptions.Item>
          ) : null}
          {campaign.adImpressions ? <Descriptions.Item label="Number of Ad Impressions">{`${campaign.adImpressions}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Descriptions.Item> : null}
          <Descriptions.Item label="Total Campaign Cost">{`$ ${campaign.campaignCost}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Descriptions.Item>
          {/* <Descriptions.Item label="File Format">{campaign.fileFormat}</Descriptions.Item> */}
          {campaign.campaignUploadBusLocId ? (
            <Descriptions.Item label="Business Locations">
              <CampaignFile id={campaign.campaignUploadBusLocId} />
            </Descriptions.Item>
          ) : null}
          {campaign.campaignUploadScreenLocId ? (
            <Descriptions.Item label="Screen Locations">
              <CampaignFile id={campaign.campaignUploadScreenLocId} />
            </Descriptions.Item>
          ) : null}
          {campaign.campaignUploadExpDevId ? (
            <Descriptions.Item label="Exposed Devices">
              <CampaignFile id={campaign.campaignUploadExpDevId} />
            </Descriptions.Item>
          ) : null}
          {campaign.campaignUploadDigLogId ? (
            <Descriptions.Item label="Digital Logs">
              <CampaignFile id={campaign.campaignUploadDigLogId} />
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      </Card>
    </>
  );
}